import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Check } from 'lucide-react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/cn';

const selectItemVariants = cva('rocco-select-item', {
    variants: {
        variant: {
            default:
                'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> &
        VariantProps<typeof selectItemVariants>
>(({ className, children, variant, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(selectItemVariants({ variant, className }))}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <SelectPrimitive.ItemIndicator>
                <Check className="h-4 w-4" />
            </SelectPrimitive.ItemIndicator>
        </span>

        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));

SelectItem.displayName = SelectPrimitive.Item.displayName;
