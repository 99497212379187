import type { DropdownProps, ShorthandCollection } from '@archipro-design/aria';

import { pxArrayToRem } from '@archipro-design/aria';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import { TextInput } from '@archipro-design/aria';
import { TextSelectorInput, useStyles } from '@archipro-design/aria';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFetcher, useLocation } from '@remix-run/react';
import type { useControllableValue } from 'ahooks';
import { useLatest } from 'ahooks';

import { Controller, useForm } from 'react-hook-form';
import { useEnquirySession } from '../../hook/use-enquiry-session';
import type { BasicPlace } from '~/modules/location/type';
import { usePlaceFinder } from '~/modules/location/hook/use-place-finder';

import * as S from './UpgradedEnquiryForm.style';
import type { Branch } from '../../util/extract-branch-from-network.server';

import { useEffect, useState } from 'react';
import type { SubmitActionResponse } from '~/routes/remix-api.enquiry.submit';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import {
    assertErrorResponse,
    assertSuccessResponse,
} from '~/modules/root/graphql/responses';
import { formatEnquiryPayload } from '../../util/format-enquiry-payload';
import { trackBrochureRequest } from '~/modules/tracking/util/trackBrochureRequest';
import { useTracker } from '@archipro-website/tracker';
import { useDirectoryURLType } from '~/modules/directory/hook/use-directory-url-type';
import type { CreateEnquirySource } from '..';
import { trackSubmitCreateEnquiryRequest } from '~/modules/tracking/util/trackSubmitCreateEnquiryRequest';
import { trackCreatedEnquiry } from '~/modules/tracking/util/trackCreatedEnquiry';
import { useEnquiryForm } from '../../hook/use-enquiry-form';
import type { Directory } from '~/modules/directory/type';
import { trackProjectEnquire } from '~/modules/tracking/util/trackProjectEnquiry';
import { isUserLoggedIn, useUser } from '~/modules/user';
import { getClosestBasicPlace } from '~/modules/location/util';
import type { EnquiryProfessional } from '../enquiry-form/EnquiryForm';
import {
    type EnquiryFormShape,
    useEnquiryFormValidator,
} from '../enquiry-validation/enquiryValidation';
import EnquiryButton from '../login-enquiry-inline/enquiry-button/EnquiryButton';
import type { LoginFlow } from '~/modules/user/hook/use-login-flow';
import { getEnquiryMessagePlaceholder } from '../../util/get-enquiry-message-placeholder';
import { trackAuthEvent } from '~/modules/user/util/track-auth-event';

interface EnquiryItemProps {
    id: number;
    type: Directory;
    title: string;
    category: string;
}

interface EnquiryFormProps {
    professional: EnquiryProfessional;
    branchOptions: DropdownItem<Branch>[];
    onSuccess?: () => void;
    onFailure?: (publicUser?: PublicUser) => void;
    onSendClick?: () => void;
    source?: CreateEnquirySource;
    siteTreeID?: number;
    messageBodyPlaceholder?: string;
    relatedItem?: EnquiryItemProps;
    disableButton: boolean;
    submitButtonText: string;
    hideButton?: boolean;
    onEnquiryChange?: (isValid: boolean) => void;
    flowProps?: ReturnType<typeof useControllableValue<LoginFlow>>;
}

interface DropdownItem<T> {
    header: string;
    value: T;
    selected?: boolean;
}

const INPUT_DEFAULT_VARIABLES = {
    inputMarginLargeDesktop: pxArrayToRem([22, 20]),
    inputMarginMediumDesktop: pxArrayToRem([22, 20]),
    inputMarginMobile: pxArrayToRem([14, 13]),
    hoverBackgroundColor: 'transparent',
};

// Note: Enquiry Side effects (sending and redirecting are handled in useEnquirySession)
const UpgradedEnquiryForm = (props: EnquiryFormProps) => {
    const {
        professional,
        branchOptions,
        onSuccess,
        onFailure,
        source = 'InPage',
        messageBodyPlaceholder,
        siteTreeID,
        relatedItem,
        disableButton,
        submitButtonText,
        hideButton,
        onEnquiryChange,
        flowProps,
    } = props;

    const { mobile } = useAppDisplayModeContext();
    const tracker = useTracker();
    const location = useLocation();
    const user = useUser();
    const isLoggedIn = isUserLoggedIn(user);
    const hasEmptyLastName = isLoggedIn && !user.LastName;
    const hasEmptyPhoneNumber = isLoggedIn && !user.Phone;
    const [flow] = flowProps || [undefined];

    const { css } = useStyles({
        visibleRowsSuburbDropdown: 5,
        visibleRowsBranchDropdown: 8,
        isMobile: mobile,
        flow,
    });

    const {
        enquirySession,
        newEnquiryDefault,
        resumeEnquiryDefault,
        setEnquirySession,
        removeEnquirySession,
        autoSubmitLocalValue,
        setAutoSubmit,
    } = useEnquirySession(undefined, source);

    const stableSetAutoSubmit = useLatest(setAutoSubmit);

    const directoryType = useDirectoryURLType();

    const { renderBranchOptions } = useEnquiryForm(directoryType);

    let defaultValues = newEnquiryDefault;

    const checkID = siteTreeID ? siteTreeID : professional.ID;
    if (checkID === enquirySession?.siteTreeId) {
        defaultValues = resumeEnquiryDefault;
    }

    const defaultBranch = branchOptions?.find(
        (branch) => branch.value.branchId === professional.ID
    );

    const formValidator = useEnquiryFormValidator(
        branchOptions,
        hasEmptyLastName
    );

    const [openBranchDropdown, setOpenBranchDropdown] = useState(false);

    const fetcher = useFetcher<SubmitActionResponse>();

    const {
        control,
        setValue,
        getValues,
        handleSubmit,
        reset,
        formState,
        getFieldState,
    } = useForm<EnquiryFormShape>({
        resolver: zodResolver(formValidator),
        mode: 'all',
    });

    const disabled =
        !!disableButton || !formState.isValid || autoSubmitLocalValue;

    // To prevent hydration errors, load default values after intial render.
    // ToDo: Move unsent enquiry sessions to cookies
    useEffect(() => {
        reset({
            ...defaultValues,
            branch: defaultBranch,
            siteTreeId: siteTreeID ?? professional.ID,
            shareDetails: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const { error: messageError } = getFieldState('message', formState);
    const { error: phoneError } = getFieldState('phoneNumber', formState);
    const validMessage = !messageError && getValues('message') !== '';
    const validPhone = !phoneError && getValues('phoneNumber') !== undefined;
    const loggedOutValidInput = !!(validMessage && validPhone);

    const messageBodyPlaceholderDefault = getEnquiryMessagePlaceholder(
        professional.Title,
        directoryType
    );

    useEffect(() => {
        onEnquiryChange?.(loggedOutValidInput);
    }, [loggedOutValidInput, onEnquiryChange]);

    const { changeKeyword: findPlace, dropdownOptions: placeOptions } =
        usePlaceFinder({
            limit: 10,
            debounceMs: 250,
        });

    const saveStateToSession = () => {
        setEnquirySession({ ...getValues() });
    };

    const setBranchBySuburbField = (input: DropdownItem<BasicPlace>) => {
        const referencePlace = input.value;
        const places = branchOptions.map((p) => p.value);
        const closestPlace = getClosestBasicPlace(referencePlace, places);
        const closestBranch = branchOptions.find((branch) => {
            return branch.value === closestPlace;
        });
        setValue('branch', closestBranch);
    };

    const loading =
        fetcher.state === 'submitting' ||
        fetcher.state === 'loading' ||
        autoSubmitLocalValue;

    const onSubmit = async (data: EnquiryFormShape) => {
        if (fetcher.state !== 'idle') return;
        const payload = formatEnquiryPayload(data);

        tracker.log('EnquirySubmit', {
            url: new URL(window.location.href),
            label: professional.Title,
        });

        trackSubmitCreateEnquiryRequest(tracker, {
            source: source,
        });

        if (directoryType === 'professional' || directoryType === 'product') {
            trackBrochureRequest(tracker, {
                type: directoryType,
                region: payload.suburbPostcode,
            });
        } else if (directoryType === 'project' && relatedItem) {
            trackProjectEnquire(tracker, {
                category: relatedItem.category,
                step: 2,
                project: relatedItem.title,
                professional: professional.Title,
                region: payload.suburbPostcode,
            });
        }

        if (autoSubmitLocalValue) {
            stableSetAutoSubmit.current?.(false);
        }

        fetcher.submit(payload, {
            method: 'post',
            action: '/remix-api/enquiry/submit',
        });

        if (source != 'EnquiryModal') {
            tracker.log('OnPageEnquirySend', {
                url: new URL(window.location.href),
                targetTracker: 'archiproTracker',
            });
        }

        removeEnquirySession();
    };

    const stableOnSuccess = useLatest(onSuccess);
    const stableOnFailure = useLatest(onFailure);

    useEffect(() => {
        const success =
            fetcher?.data &&
            assertSuccessResponse(fetcher.data) &&
            fetcher.state === 'idle';
        const error =
            fetcher?.data &&
            assertErrorResponse(fetcher.data) &&
            fetcher.state === 'idle';
        if (success) {
            stableOnSuccess.current?.();
            trackCreatedEnquiry(tracker, { source });

            if (
                fetcher.data &&
                'data' in fetcher.data &&
                fetcher.data.data &&
                'GeneratedMember' in fetcher.data.data &&
                fetcher.data.data.GeneratedMember
            ) {
                trackAuthEvent({
                    tracker,
                    event: 'new_account_registered',
                    provider: 'AutoGenerated',
                    authSource: 'enquiry',
                    guestID: fetcher.data.data.GeneratedMember.TrackedGuest?.ID,
                });
            }
        }
        if (error) {
            setEnquirySession({ ...getValues() });
            const publicUser =
                (fetcher.data &&
                    'publicUser' in fetcher.data &&
                    fetcher.data?.publicUser) ||
                undefined;
            stableOnFailure.current?.(publicUser);
        }
    }, [
        fetcher,
        stableOnSuccess,
        stableOnFailure,
        setEnquirySession,
        getValues,
        tracker,
        source,
    ]);

    useEffect(() => {
        const checkID = siteTreeID ? siteTreeID : professional.ID;
        if (checkID !== enquirySession?.siteTreeId) {
            setValue('message', '');
        }
    }, [
        enquirySession?.siteTreeId,
        location,
        professional.ID,
        setValue,
        siteTreeID,
    ]);

    return (
        <fetcher.Form
            id={`enquiryForm_${source}`}
            method="post"
            onSubmit={handleSubmit((data) => onSubmit(data))}
            action={'/remix-api/enquiry/submit'}
            className={css(S.EnquiryForm)}
            noValidate={true}
        >
            <Controller
                control={control}
                name={'message'}
                render={({ field, fieldState: { error } }) => (
                    <TextInput
                        className={css(S.MessageInput)}
                        input={{
                            ...field,
                            id: 'message-input',
                            placeholder:
                                messageBodyPlaceholder ??
                                messageBodyPlaceholderDefault,
                            required: true,
                            input: {
                                as: 'textarea',
                                className: 'data-hj-allow',
                            },
                            onBlur: () => {
                                field.onBlur();
                                saveStateToSession();
                            },
                        }}
                        variables={INPUT_DEFAULT_VARIABLES}
                        state={error?.message ? 'error' : 'default'}
                        message={error?.message}
                    />
                )}
            />
            <div className={css(S.EnquiryGrid)}>
                <Controller
                    control={control}
                    name={'phoneNumber'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            input={{
                                ...field,
                                id: 'phoneNumber-input',
                                placeholder: 'Phone number*',
                                required: true,
                                disabled: isLoggedIn && !hasEmptyPhoneNumber,
                                onBlur: () => {
                                    field.onBlur();
                                    saveStateToSession();
                                },
                                input: {
                                    className: 'data-hj-allow',
                                },
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />

                <Controller<EnquiryFormShape>
                    control={control}
                    name={'suburbPostcode'}
                    render={({
                        field: { onBlur, ref, value },
                        fieldState: { error },
                    }) => (
                        <TextSelectorInput
                            className={css(S.SuburbDropdown)}
                            input={{
                                ref,
                                placeholder: 'Suburb / Postcode*',
                            }}
                            dropdown={{
                                value,
                                defaultSearchQuery:
                                    (
                                        value as EnquiryFormShape['suburbPostcode']
                                    )?.header ??
                                    defaultValues?.suburbPostcode?.header,
                                items: placeOptions,
                                noResultsMessage: 'No results found.',
                                onSearchQueryChange: (_, data) => {
                                    findPlace(data.searchQuery);
                                },
                                search: (
                                    items: ShorthandCollection<
                                        DropdownProps['items']
                                    >
                                ) => items,
                                onChange: (_, data) => {
                                    const place =
                                        data.value as EnquiryFormShape['suburbPostcode'];
                                    setValue(
                                        'suburbPostcode',
                                        place ?? undefined
                                    );
                                    if (place) {
                                        setBranchBySuburbField(place);
                                    }
                                },
                                offset: [0, 20],
                                onBlur: () => {
                                    onBlur();
                                    saveStateToSession();
                                },
                                searchInput: {
                                    input: {
                                        className: 'data-hj-allow',
                                    },
                                },
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />
            </div>

            {renderBranchOptions && branchOptions.length > 0 && (
                <Controller<EnquiryFormShape>
                    control={control}
                    name={'branch'}
                    render={({
                        field: { value, onBlur, ref },
                        fieldState: { error },
                    }) => (
                        <TextSelectorInput
                            className={css(S.BranchDropdown)}
                            input={{
                                ref,
                                placeholder: 'Select branch',
                                required: true,
                            }}
                            showChevronIcon={true}
                            dropdown={{
                                open: openBranchDropdown,
                                value,
                                items: branchOptions.map((branch) => {
                                    const active =
                                        value as EnquiryFormShape['branch'];
                                    const selected =
                                        branch.header === active?.header;
                                    return { selected, ...branch };
                                }),
                                onChange: (_, data) => {
                                    const branch =
                                        data.value as EnquiryFormShape['branch'];
                                    setValue('branch', branch ?? undefined);
                                },
                                onOpenChange: (_, data) => {
                                    data.open !== undefined &&
                                        setOpenBranchDropdown(data.open);
                                },
                                onBlur: () => {
                                    onBlur();
                                    saveStateToSession();
                                },
                                clearIndicator: '',
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />
            )}
            {!hideButton && (
                <div className={css(S.EnquiryButtonContainer)}>
                    <EnquiryButton
                        buttonText={submitButtonText}
                        disabled={disabled}
                        loading={loading}
                        buttonKey="enquiry-submit-button"
                        type="submit"
                    />
                </div>
            )}
        </fetcher.Form>
    );
};

export default UpgradedEnquiryForm;
