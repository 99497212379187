import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { z } from 'zod';
import { useAtomValue } from 'jotai';

import { cn } from '@rocco/utils/cn';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { Button } from '@archipro/rocco/components/button';
import { uiContextAtomDisplayMode } from '@rocco/states/uiContextAtomDisplayMode';
import { Input } from '@rocco/components/input';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@rocco/components/form';
import { PopoverAnchor } from '@rocco/components/popover';
import { RoccoLink } from '@archipro/rocco/components/link/RoccoLink';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@rocco/components/select';
import { Textarea } from '@rocco/components/textarea';
import { usePlaceFinder } from '@modules/location/hook/use-place-finder';
import { getClosestBasicPlace } from '@modules/location/util';
import { useUserLoggedIn } from '@modules/user';
import type { ProfessionalInitialResult } from '@modules/professional/hook/use-professional-initial-route-data';
import type { Branch } from '@modules/enquiry/util/extract-branch-from-network.server';
import {
    useEnquiry,
    type UseEnquiryProps,
} from '@modules-rocco/enquiry/hooks/use-enquiry';
import { PostcodePopover } from './PostcodePopover';

interface EnquiryFormProps {
    className?: string;
    professional: Pick<
        ProfessionalInitialResult,
        'ID' | 'Title' | 'branchOptions' | 'CustomEnquiryMessage'
    >;
    siteTreeID?: UseEnquiryProps['siteTreeID'];
    source?: UseEnquiryProps['source'];
    relatedItem?: UseEnquiryProps['relatedItem'];
    // TODO Do not show branch on articles or projects
    includeBranch?: boolean;
    showCustomEnquiryMessage?: boolean;
    submitButtonText?: string;
    disableSubmit?: boolean;
    showSuccessMessage?: boolean;
    onSuccess?: UseEnquiryProps['onSuccess'];
    onFailure?: UseEnquiryProps['onFailure'];
}

/**
 * Form for submitting an enquiry to a professional.
 *
 * This is the new design in place of the old enquiry form, currently used as an experiment.
 * @see {@link packages/web/app/modules/enquiry/component/enquiry-form/EnquiryForm.tsx}
 */
export const EnquiryForm = ({
    className,
    professional,
    siteTreeID,
    source = 'InPage',
    relatedItem,
    includeBranch = true,
    showCustomEnquiryMessage = true,
    submitButtonText = 'Submit',
    disableSubmit = false,
    showSuccessMessage = true,
    onSuccess,
    onFailure,
}: EnquiryFormProps) => {
    const { user, isLoggedIn } = useUserLoggedIn();
    const { isDesktop } = useAtomValue(uiContextAtomDisplayMode);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [isSuccessAnimationPlaying, setIsSuccessAnimationPlaying] =
        useState(false);
    const [postcodeSearch, setPostcodeSearch] = useState('');
    const [isPostcodeSearchOpen, setIsPostcodeSearchOpen] = useState(false);
    const postcodeInputRef = useRef<HTMLInputElement | null>(null);
    const successAnimationTimeout = useRef<number>();
    const {
        changeKeyword: findPlace,
        dropdownOptions: placeOptions,
        loading: isPlaceOptionsLoading,
    } = usePlaceFinder({
        limit: 10,
        debounceMs: 250,
    });
    const {
        defaultFormValues,
        formSchema,
        state,
        autoSubmitDelay,
        submitEnquiry,
        saveEnquirySession,
    } = useEnquiry({
        professional,
        siteTreeID,
        source,
        relatedItem,
        includeBranch,
        onSuccess: () => {
            form.reset();
            playSuccessAnimation();
            onSuccess?.();
        },
        onFailure,
    });
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        disabled: state !== 'idle' || autoSubmitDelay,
        defaultValues: defaultFormValues,
    });

    const autoSelectBranch = useCallback(
        (postcode: (typeof placeOptions)[number]) => {
            // TODO: test branch options when used
            if (
                !includeBranch ||
                !professional.branchOptions.length ||
                form.getValues('branchId')
            ) {
                return;
            }
            const branches = professional.branchOptions.map((p) => p.value);
            const closestPlace = getClosestBasicPlace(
                postcode.value,
                branches
            ) as Branch | undefined;
            const closestBranch = branches.find((branch) => {
                return branch.branchId === closestPlace?.branchId;
            });
            if (closestBranch) {
                form.setValue('branchId', closestBranch.branchId.toString());
            }
        },
        [includeBranch, professional, form]
    );

    const selectPostcode = useCallback(
        (place: (typeof placeOptions)[number] | undefined) => {
            if (place) {
                form.setValue('postcode', place);
                setPostcodeSearch(place.header);
                autoSelectBranch(place);
            } else {
                form.resetField('postcode', { defaultValue: undefined });
                setPostcodeSearch('');
            }
            setIsPostcodeSearchOpen(false);
            saveEnquirySession({ ...form.getValues(), postcode: place });
        },
        [form, autoSelectBranch, saveEnquirySession]
    );

    /**
     * Automatically select the first postcode option if the user has typed a
     * postcode, there are options available, and postcode is not set yet.
     */
    const autoSelectPostcode = useCallback(() => {
        if (
            postcodeSearch &&
            placeOptions.length &&
            !form.getValues('postcode')
        ) {
            selectPostcode(placeOptions[0]);
            form.clearErrors('postcode');
        }
    }, [postcodeSearch, placeOptions, form, selectPostcode]);

    /**
     * Select the first available postcode option from the dropdown options.
     * Does not change the selection if the selected postcode is in the dropdown.
     */
    const selectAvailablePostCode = useCallback(() => {
        if (placeOptions.length && !isPlaceOptionsLoading) {
            const selectedPlace = placeOptions.find(
                (place) =>
                    place.value.addressID ===
                    form.getValues('postcode')?.value.addressID
            );
            if (selectedPlace) {
                selectPostcode(selectedPlace);
            } else {
                selectPostcode(placeOptions[0]);
            }
        }
    }, [placeOptions, isPlaceOptionsLoading, form, selectPostcode]);

    const playSuccessAnimation = () => {
        clearTimeout(successAnimationTimeout.current);
        setIsSubmitSuccess(true);
        setIsSuccessAnimationPlaying(true);
        successAnimationTimeout.current = setTimeout(
            () => {
                setIsSuccessAnimationPlaying(false);
            },
            3000,
            undefined
        );
    };

    useEffect(() => {
        // Auto select the first postcode option if the input is not focused and no value is set.
        if (document.activeElement !== postcodeInputRef.current) {
            autoSelectPostcode();
        }
    }, [autoSelectPostcode]);

    useEffect(() => {
        if (!form.formState.isDirty) {
            // This is required to load the saved session data.
            form.reset(defaultFormValues);
            if (!postcodeSearch && form.getValues('postcode')) {
                setPostcodeSearch(form.getValues('postcode').header);
            }
        }
    }, [defaultFormValues, form, postcodeSearch]);

    return (
        <div className={cn('relative', className)}>
            <div
                className={cn(
                    'space-y-8',
                    isSubmitSuccess && showSuccessMessage && 'invisible'
                )}
            >
                {showCustomEnquiryMessage &&
                    !!professional.CustomEnquiryMessage && (
                        <div className="whitespace-pre-wrap text-base">
                            {professional.CustomEnquiryMessage}
                        </div>
                    )}
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(submitEnquiry)}
                        className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2"
                    >
                        <FormField
                            name="name"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel
                                        className="text-base"
                                        errorVariant="errorDarker"
                                    >
                                        Full Name*
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            text="default"
                                            color="lightOutline"
                                            placeholder="e.g. John Doe"
                                            autoComplete="name"
                                            {...field}
                                            onBlur={() => {
                                                field.onBlur();
                                                saveEnquirySession(
                                                    form.getValues()
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage variant="darker" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="email"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel
                                        className="text-base"
                                        errorVariant="errorDarker"
                                    >
                                        Email*
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            type="email"
                                            text="default"
                                            color="lightOutline"
                                            placeholder="e.g. johndoe@gmail.com"
                                            autoComplete="email"
                                            disabled={
                                                isLoggedIn && !!user.Email
                                            }
                                            {...field}
                                            onBlur={() => {
                                                field.onBlur();
                                                saveEnquirySession(
                                                    form.getValues()
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage variant="darker" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="phoneNumber"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel
                                        className="text-base"
                                        errorVariant="errorDarker"
                                    >
                                        Phone number*
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            type="tel"
                                            text="default"
                                            color="lightOutline"
                                            placeholder="+1 234 567 8910"
                                            autoComplete="tel"
                                            {...field}
                                            onBlur={() => {
                                                field.onBlur();
                                                saveEnquirySession(
                                                    form.getValues()
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage variant="darker" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="postcode"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel
                                        className="text-base"
                                        errorVariant="errorDarker"
                                    >
                                        Suburb / Postcode*
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            text="default"
                                            color="lightOutline"
                                            showClearButton
                                            autoComplete="off"
                                            maxLength={100}
                                            {...field}
                                            ref={(el) => {
                                                postcodeInputRef.current = el;
                                                field.ref(el);
                                            }}
                                            value={postcodeSearch}
                                            onChange={(e) => {
                                                setPostcodeSearch(
                                                    e.target.value
                                                );
                                                findPlace(e.target.value);
                                                if (e.target.value) {
                                                    setIsPostcodeSearchOpen(
                                                        true
                                                    );
                                                } else {
                                                    selectPostcode(undefined);
                                                }
                                            }}
                                            onFocus={() => {
                                                if (postcodeSearch) {
                                                    setIsPostcodeSearchOpen(
                                                        true
                                                    );
                                                    if (!placeOptions.length) {
                                                        findPlace(
                                                            postcodeSearch
                                                        );
                                                    }
                                                }
                                            }}
                                            onBlur={() => {
                                                field.onBlur();
                                                autoSelectPostcode();
                                                setIsPostcodeSearchOpen(false);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    selectAvailablePostCode();
                                                }
                                            }}
                                        />
                                    </FormControl>
                                    <PostcodePopover
                                        open={isPostcodeSearchOpen}
                                        onOpenChange={(open) => {
                                            if (
                                                !open &&
                                                document.activeElement ===
                                                    postcodeInputRef.current
                                            ) {
                                                // Prevent popover from closing when the input is focused.
                                                return;
                                            }
                                            setIsPostcodeSearchOpen(open);
                                        }}
                                        value={field.value}
                                        placeOptions={placeOptions}
                                        isLoading={isPlaceOptionsLoading}
                                        onSelect={selectPostcode}
                                    >
                                        <div className="absolute">
                                            <PopoverAnchor className="-mt-2" />
                                        </div>
                                    </PostcodePopover>
                                    <FormMessage variant="darker" />
                                </FormItem>
                            )}
                        />
                        {includeBranch &&
                            !!professional.branchOptions?.length && (
                                <FormField
                                    name="branchId"
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem className="md:col-span-2">
                                            <FormLabel
                                                className="text-base"
                                                errorVariant="errorDarker"
                                            >
                                                Branch*
                                            </FormLabel>
                                            <Select
                                                onValueChange={(value) => {
                                                    field.onChange(value);
                                                    saveEnquirySession(
                                                        form.getValues()
                                                    );
                                                }}
                                                value={field.value}
                                                disabled={field.disabled}
                                            >
                                                <FormControl>
                                                    <SelectTrigger color="lightOutline">
                                                        <SelectValue placeholder="Select branch" />
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent
                                                    className="bg-transparent backdrop-blur-2xl"
                                                    borderless
                                                >
                                                    {professional.branchOptions.map(
                                                        (branch) => (
                                                            <SelectItem
                                                                key={
                                                                    branch.value
                                                                        .branchId
                                                                }
                                                                className="py-4 pr-4 focus:bg-black/10"
                                                                value={branch.value.branchId.toString()}
                                                            >
                                                                {branch.header}
                                                            </SelectItem>
                                                        )
                                                    )}
                                                </SelectContent>
                                            </Select>
                                            <FormMessage variant="darker" />
                                        </FormItem>
                                    )}
                                />
                            )}
                        <FormField
                            name="message"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="md:col-span-2">
                                    <FormLabel
                                        className="text-base"
                                        errorVariant="errorDarker"
                                    >
                                        Message*
                                    </FormLabel>
                                    <FormControl>
                                        <Textarea
                                            text="default"
                                            color="lightOutline"
                                            placeholder="Please be descriptive with your enquiry to ensure a good response."
                                            rows={isDesktop ? 8 : 7}
                                            {...field}
                                            onBlur={() => {
                                                field.onBlur();
                                                saveEnquirySession(
                                                    form.getValues()
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage variant="darker" />
                                </FormItem>
                            )}
                        />
                        <div className="mt-4 flex justify-end md:col-span-2">
                            <Button
                                type="submit"
                                className="px-9 py-2.5"
                                disabled={
                                    disableSubmit || form.formState.disabled
                                }
                                isLoading={form.formState.disabled}
                            >
                                {submitButtonText}
                                <ArrowRight className="ml-2 size-5" />
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
            {showSuccessMessage && (
                <div
                    className={cn(
                        'absolute left-0 top-0 z-1 hidden h-full w-full flex-col overflow-hidden rounded-xl',
                        isSubmitSuccess && 'flex'
                    )}
                    role="alert"
                    aria-live="assertive"
                >
                    <img
                        src="/assets/website/ui/images/glassy-background.png"
                        alt=""
                        className="pointer-events-none absolute left-0 top-0 -z-1 h-full w-full select-none object-cover"
                    />
                    <div className="border-b border-white px-4 pb-4 pt-6 text-lg md:px-8">
                        Message sent
                    </div>
                    <div className="relative flex grow flex-col items-center justify-center p-4 text-center text-base will-change-contents md:p-8">
                        {/* Need to recreate video to start playback from beginning. */}
                        {isSubmitSuccess && (
                            <video
                                src="/assets/website/ui/videos/message-sent.mp4"
                                autoPlay
                                playsInline
                                muted
                                className={cn(
                                    'absolute left-1/2 top-1/2 size-20 -translate-x-1/2 -translate-y-1/2 transform-gpu mix-blend-darken duration-300 will-change-transform',
                                    !isSuccessAnimationPlaying &&
                                        'translate-y-[calc(-50%-3.5rem)] transition-transform'
                                )}
                            />
                        )}
                        {!isSuccessAnimationPlaying && (
                            <div className="duration-500 will-change-[opacity] animate-in fade-in">
                                <div className="mb-10 h-20">
                                    {/* Imaginary icon */}
                                </div>
                                <div className="mb-2 text-lg font-medium">
                                    Thank you for your enquiry!
                                </div>
                                <div>
                                    We will get back to you as soon as possible.
                                </div>
                                <div>
                                    You will receive a notification in your
                                    email:{' '}
                                    {isLoggedIn
                                        ? user.Email
                                        : form.getValues('email')}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end gap-x-4 border-t border-white px-4 py-4 md:gap-x-6 md:px-8">
                        <Button
                            color="white"
                            weight="normal"
                            className="px-6 py-2.5 md:px-8"
                            asChild
                        >
                            <RoccoLink to="/member/inbox" isNonRemixRoute>
                                View My Inbox
                                <ArrowRight className="ml-2 size-5" />
                            </RoccoLink>
                        </Button>
                        <Button
                            className="px-8 py-2.5 md:px-16"
                            onClick={() => setIsSubmitSuccess(false)}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
