import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const selectSeparatorVariants = cva('rocco-select-separator', {
    variants: {
        variant: {
            default: '-mx-1 my-1 h-px bg-muted',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    OmitStylesComponentProps<typeof SelectPrimitive.Separator> &
        VariantProps<typeof selectSeparatorVariants>
>(({ variant, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={selectSeparatorVariants({ variant })}
        {...props}
    />
));

SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
