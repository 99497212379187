import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { ChevronDown } from 'lucide-react';
import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const selectScrollDownButtonVariants = cva('rocco-select-scroll-down-button', {
    variants: {
        variant: {
            default: 'flex cursor-default items-center justify-center py-1',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SelectScrollDownButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
    OmitStylesComponentProps<typeof SelectPrimitive.ScrollDownButton> &
        VariantProps<typeof selectScrollDownButtonVariants>
>(({ variant, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={selectScrollDownButtonVariants({ variant })}
        {...props}
    >
        <ChevronDown className="h-4 w-4" />
    </SelectPrimitive.ScrollDownButton>
));

SelectScrollDownButton.displayName =
    SelectPrimitive.ScrollDownButton.displayName;
