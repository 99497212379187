import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const selectLabelVariants = cva('rocco-select-label', {
    variants: {
        variant: {
            default: 'py-1.5 pl-8 pr-2 text-sm font-semibold',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const SelectLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    OmitStylesComponentProps<typeof SelectPrimitive.Label> &
        VariantProps<typeof selectLabelVariants>
>(({ variant, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={selectLabelVariants({ variant })}
        {...props}
    />
));

SelectLabel.displayName = SelectPrimitive.Label.displayName;
