import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ChevronDown } from 'lucide-react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/cn';
import { OmitStylesComponentProps } from '../../types';

const selectTriggerVariants = cva(
    'rocco-select-trigger focus:ring-ring rounded-2 flex w-full items-center justify-between focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
    {
        variants: {
            variant: {
                default: 'px-4 py-3',
                small: 'p-3',
                medium: 'px-6 py-3',
                wide: 'px-9 py-3',
                large: 'px-8 py-4',
            },
            text: {
                default: 'text-[16px] leading-none md:text-base',
                small: 'text-3.5 leading-none',
                medium: 'text-[16px] font-medium leading-none file:font-medium data-[placeholder]:font-medium md:text-base',
            },
            color: {
                default:
                    'text-grey-900 border border-gray-400 bg-black/5 data-[placeholder]:text-gray-400',
                lightOutline:
                    'text-grey-900 border border-gray-200 bg-transparent data-[placeholder]:text-gray-500/70',
            },
        },
        defaultVariants: {
            variant: 'default',
            text: 'default',
            color: 'default',
        },
    },
);

export const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    OmitStylesComponentProps<typeof SelectPrimitive.Trigger> &
        VariantProps<typeof selectTriggerVariants> & {
            className?: string;
            hideIcon?: boolean;
        }
>(
    (
        {
            children,
            className,
            variant,
            text,
            color,
            hideIcon = false,
            ...props
        },
        ref,
    ) => (
        <SelectPrimitive.Trigger
            ref={ref}
            className={cn(
                selectTriggerVariants({ variant, text, color, className }),
            )}
            {...props}
        >
            {children}
            {!hideIcon && (
                <SelectPrimitive.Icon asChild>
                    <ChevronDown className="h-4 w-4 opacity-50" />
                </SelectPrimitive.Icon>
            )}
        </SelectPrimitive.Trigger>
    ),
);

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
