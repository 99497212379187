import React from 'react';
import { Check } from 'lucide-react';
import { cn } from '@rocco/utils/cn';
import { Popover, PopoverContent } from '@rocco/components/popover';
import type { getPlaceDropdownOptions } from '~/modules/location/util/get-place-dropdown-options';

type PostcodeOptions = ReturnType<typeof getPlaceDropdownOptions>;

type PostcodeDropdownProps = React.ComponentProps<typeof Popover> & {
    value?: { header: string; value: { addressID?: string } };
    placeOptions?: PostcodeOptions;
    isLoading?: boolean;
    onSelect?: (place: PostcodeOptions[number]) => void;
};

/**
 * Popover dropdown for postcodes/places.
 */
export const PostcodePopover = ({
    children,
    value,
    placeOptions = [],
    isLoading = false,
    onSelect,
    ...props
}: PostcodeDropdownProps) => {
    return (
        <Popover {...props}>
            {children}
            <PopoverContent
                align="start"
                background="transparent"
                className="min-w-[24rem] overflow-auto rounded-lg p-0 before:rounded-lg"
                // Prevent popover from getting focus (input should be focused).
                onOpenAutoFocus={(e) => e.preventDefault()}
            >
                {isLoading ? (
                    <div className="p-4" role="status" aria-live="polite">
                        Loading...
                    </div>
                ) : placeOptions.length ? (
                    <div className="flex flex-col">
                        {placeOptions.map((place) => (
                            <button
                                key={place.value.addressID}
                                className="align-center flex justify-between p-4 hover:bg-black/10"
                                onClick={() => onSelect?.(place)}
                            >
                                {place.header}
                                <Check
                                    className={cn(
                                        'invisible ml-2 size-4',
                                        place.value.addressID ===
                                            value?.value.addressID && 'visible'
                                    )}
                                />
                            </button>
                        ))}
                    </div>
                ) : (
                    <div className="p-4">No results found.</div>
                )}
            </PopoverContent>
        </Popover>
    );
};
