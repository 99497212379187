import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { ChevronUp } from 'lucide-react';
import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const selectScrollUpButtonVariants = cva('rocco-select-scroll-up-button', {
    variants: {
        variant: {
            default: 'flex cursor-default items-center justify-center py-1',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
export const SelectScrollUpButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
    OmitStylesComponentProps<typeof SelectPrimitive.ScrollUpButton> &
        VariantProps<typeof selectScrollUpButtonVariants>
>(({ variant, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={selectScrollUpButtonVariants({ variant })}
        {...props}
    >
        <ChevronUp className="h-4 w-4" />
    </SelectPrimitive.ScrollUpButton>
));

SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
